<template>
    <div class="main padding-20">
        <div class="column" v-loading="pageloading">
        <el-tabs v-model="activeName" @tab-click="tabhandleClick" class="reporttab">
            <el-tab-pane :label="it.title" :name="it.code" :key="it.code" v-for="it of topTablist"></el-tab-pane>
        </el-tabs>
        <!-- 预测报告 -->
        <div  v-show="activeName=='yu_ce_bao_gao_edit'  && prodvalue>0">
                    <!-- 头部筛选 -->
                <div class="flex-row ">
                    <div class="margin-right-20 ">
                        <el-select v-model="prodvalue" placeholder="请选择" @change="changeForeproduct" size="mini">
                            <el-option
                            v-for="item in prodoptions"
                            :key="item.productId"
                            :label="item.productName"
                            :value="item.productId">
                            </el-option>
                        </el-select>
                    </div>
                    <div  class="margin-right-20">
                        <el-select v-model="frefqValue" @change="changeforeFreq" placeholder="请选择" size="mini">
                            <el-option key="0" label="周度" value="week"></el-option>
                            <el-option key="1" label="月度" value="month"></el-option>
                        </el-select>
                    </div>
                    <div>
                        <el-date-picker
                        v-model="foreDatarange"
                        value-format="yyyy-MM"
                        @change="changedate"
                        type="monthrange"
                        size="mini"
                        range-separator="至"
                        start-placeholder="开始月份"
                        end-placeholder="结束月份">
                        </el-date-picker>
                    </div>
                </div>
                <!-- 头部结束 -->
                <div class="margin-top-15">
                    <el-table
                        :data="foreReportlist"
                        style="width: 100%">
                        <el-table-column
                            prop="productName"
                            label="产品"
                           >
                        </el-table-column>
                        <el-table-column
                            prop="reportName"
                            label="报告名称"
                           >
                           
                        </el-table-column>
                        <el-table-column
                            prop="startCycleShow"
                            label="报告期">
                            <template v-slot="scope" >
                               {{frefqValue=='week'?scope.row.startCycleShow:$dateFormat(scope.row.startCycleShow,'yyyy-MM')}}
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="操作">
                            <template v-slot="scope" >
                                <span class="link-text" @click="showreprot(scope.row)">查看</span>&nbsp;
                                <span class="link-text"  @click="shareforereport(scope.row)">分享</span>
                            </template>
                        </el-table-column>
                        </el-table>
                </div>
                </div>
                 <!-- 预测报告结束 -->
           <!-- 线下报告开始 -->
        <div  v-show="activeName=='xian_xia_bao_gao' && prodvalue>0">
                    <div class="flex-row justify-between">
                        <div class="flex-row ">
                            <div class="margin-right-20">
                                <el-select v-model="prodvalue" placeholder="请选择" @change="changeofflineproduct"  size="mini">
                                    <el-option
                                    v-for="item in prodoptions"
                                    :key="item.productId"
                                    :label="item.productName"
                                    :value="item.productId">
                                    </el-option>
                                </el-select>
                            </div>
                            <div>
                                <el-input v-model="keywords" placeholder="请输入内容" @change="searchuploadreport" size="mini"></el-input>
                            </div>
                        </div>
                        <div>
                            <el-upload
                                class="upload-demo"
                                :action="$server+apiurl.updateurl+'?productId='+prodvalue"
                                :headers="{token:$store.state.loginRes.token}"
                                :on-success="uploadsuccess"
                                :accept="'.pdf'"
                                :on-error="uploaderror"
                                :before-upload="beforeAvatarUpload"
                                :show-file-list="false"
                                >
                                <el-button size="mini" type="primary">上传报告</el-button>
                                <div slot="tip" class="el-upload__tip"></div>
                                </el-upload>
                        </div>
                    </div>
                    <div class="margin-top-15">
                            <!-- 表格数据开始 -->
                            <el-table
                                :data="tableData"
                                style="width: 100%">
                                <el-table-column
                                    prop="productName"
                                    label="产品"
                                >
                                </el-table-column>
                                <el-table-column
                                    label="报告名称"
                                >
                                <template  v-slot="scope" >
                                    <a class="link-text"  @click="showreport(scope.row)">{{scope.row.reportName}}</a>
                                </template>
                                </el-table-column>
                                <el-table-column
                                    prop="createTime"
                                    label="上传时间">
                                </el-table-column>
                                <el-table-column
                                    label="操作">
                                    <template  v-slot="scope" >
                                        <a class="margin-right-5 link-text"  @click="showreport(scope.row)">查看</a>
                                        <a class="margin-right-5 link-text"  @click="shareofflinereport(scope.row)">分享</a>
                                        <a class="link-text"  @click="deletereport(scope.row)">删除</a>
                                    </template>
                                </el-table-column>
                            </el-table>
                        
                            <!-- 表格数据结束 -->
                    </div>
                </div>
                 <!-- 线下报告结束 -->      
         <!-- 分享弹窗开始 -->
         <el-dialog
            title="分享链接"
            :visible.sync="shareVisible"
           
            width="340px"
            custom-class="custom-dialog"
            :modal="false"
            class="sharedialog"
           >
            <div class="flex-row justify-between">
                <div>
                    <div class="color-sub line-height-larger">分享链接:</div>
                    <div>{{sharerow.type=='forecase'?baseurl:$server}}{{sharerow.fileurl}}</div>
                </div>
                <div class="link-text font-size-14 line-height-larger text-nowrap margin-left-20" @click="clickCopy(`${sharerow.type=='forecase'?baseurl:$server}${sharerow.fileurl}`)">复制</div>
            </div>
            <div class="margin-top-10">
                    <div  class="color-sub line-height-larger">{{sharerow.type=='forecase'?'预测报告':'文件名称'}}:</div>
                    <div>{{sharerow.filename}}</div>
                </div>
            <span></span>
            <span slot="footer" class="dialog-footer flex-row justify-center">
                <el-button type="primary" @click="shareVisible = false" class="sharebtn" size="mini">确 定</el-button>
            </span>
        </el-dialog>
         <!-- 分享弹窗结束 -->
        </div>
    </div>
   
</template>
<script>
export default {
    name:"allreport",
    data() {
      return{
        activeName:"yu_ce_bao_gao_edit",//tab选中数据
        prodvalue:"",//产品列表选中
      //  foreprodvalue:"",//预测报告参评筛选
        prodoptions:[], //产品列表
        frefqValue:"week",//报告周期选中
        foreDatarange:"",//预测报告筛选时间
        startDate:"",
        endDate:"",
        pageloading:false,
        keywords:"",//线下报告搜索关键词
        tableData:[],
        shareVisible:false,
        sharerow:{fileurl:"",filename:""},
        tableDataAll:[],
        baseurl:""+window.location.href.split('#')[0],
        apiurl:{
            getupdatereport:"/reportOffline/getReportOfflineInfos",
            deleteupdatereport:"/reportOffline/deleteReportOfflineById",
            updateurl:"/reportOffline/upload",
            shareurl:"/reportOffline/shareReportOffline",
            getforecast:"/forecastReport/getForecastReports"
        },
        foreReportlist:[],
        topTablist:[]
      }  
    },
    methods:{
        showreprot(row){//查看预测报告
            const href = this.$router.resolve({
                path: `/forecastapply/reportshare`,
                query:{pid:row.productId,freq:row.modelFreq,startCycle:row.startCycle}
            }).href;
            //打开新的页面 到 href 这个页面
            window.open(href, "_blank");
        },
        shareforereport(row){
            const href = this.$router.resolve({
                path: `/forecastapply/reportshare`,
                query:{pid:row.productId,freq:row.modelFreq,startCycle:row.startCycle}
            }).href;
            this.sharerow.filename=row.reportName;
            this.sharerow.fileurl=href;
            this.sharerow.type="forecase"
            this.shareVisible=true;
        },
        tabhandleClick(val){
            //切换tab标签触发
            // console.log(val.name);
            this.activeName=val.name;
            this.init()
        },
        uploadsuccess(){
            this.$message({
            message: '操作成功！',
            type: 'success'
            });
            this.getList();
        },
        getforecastreport(){
            this.foreReportlist=[];
            if(this.prodvalue && this.prodvalue>0){
                this.$instance.get(this.apiurl.getforecast,{params:{productId:this.prodvalue,modelFreq:this.frefqValue,startDate:this.startDate,endDate:this.endDate}}).then(res=>{
                    if(res.data.code==0){
                        this.foreReportlist=res.data.info;
                    }
                    this.pageloading=false;
                }).catch(() => {
                    this.pageloading=false;       
                });
            }
            
        },
        changeForeproduct(){
          //  console.log('changeForeproduct');
            this.getforecastreport();
        },
        changedate(val){
          //  console.log(val)
            if(val&&val.length>=2){
               this.startDate=val[0];
               this.endDate=val[1]
            }else{
                this.$message({
                message: '请选择开始和结束时间',
                type: 'error'
                });
            }
           
            this.getforecastreport();
        },
        changeforeFreq(){//预测报告数据周期切换
            this.$nextTick(()=>{
                this.getforecastreport();
            })
        },
        uploaderror(){
            this.$message({
            message: '操作失败，请稍后重试！',
            type: 'error'
            });
        },
        beforeAvatarUpload(file){
            console.log(file);
            const isPDF = file.type === 'application/pdf';
            const isLt5M = file.size / 1024 / 1024 < 30;

            if (!isPDF) {
            this.$message.error('请上传PDF格式的文件!');
            }
            if (!isLt5M) {
            this.$message.error('文件大小不能超过 30MB!');
            }
            return isPDF && isLt5M;
        },
        searchuploadreport(){
          
            //搜索表格数据
            if(this.keywords.trim().length>0){
                console.log(this.keywords);
                this.tableData=this.tableDataAll.filter(o=>{return o.reportName.toString().indexOf(this.keywords.trim())>=0})
            }else{
                this.tableData=this.tableDataAll;
            }
        },
        changeofflineproduct(){
            this.getList();
        },
        getList(){
            this.tableData=[];
            this.tableDataAll=[];
            //获取线下报告表格列表
            if(this.prodvalue && this.prodvalue>0){
            this.$instance.get(this.apiurl.getupdatereport,{params:{productId:this.prodvalue}}).then(res=>{
                if(res.data.code==0){
                        this.tableData=res.data.info;   
                        this.tableDataAll=res.data.info; 
                }
                this.pageloading=false;
            }).catch(() => {
                this.pageloading=false;       
            });}

          
        },

        async getproductList(){
            //获取产品列表用于筛选
            this.prodoptions=[];
            this.prodvalue="";
            let temparams={}
            if(this.activeName=="yu_ce_bao_gao_edit"){
                temparams={moduleCode:this.activeName,isReport:1}
            }else{
                temparams={moduleCode:this.activeName}
            }
           
            await  this.$instance.get('/manage/getProductPowerWithCustom',{params:temparams}).then(res=>{
                if(res.data.code==0 && res.data.info){
                    this.prodoptions=res.data.info;
                    
                    //设置默认选中第一个权限产品
                    if(this.prodoptions[0]){
                        this.prodvalue= this.prodoptions[0].productId;
                       // this.targetId=this.options[0].targetId;
                    }else{
                        this.pageloading=false
                        this.$alert(`您暂无此模块的产品权限`, '提示', {
                        customClass:"custom-msgbox",
                        confirmButtonText: '确定',
                        showClose:false,
                        type: 'warning'
                        })
                       
                        // .then(() => {
                        // this.$router.replace({
                        //     path:'/'
                        // })
                        // })
                    }
                }
                
            })
           
        },
        showreport(row){
          
            window.open(this.$server+""+row.fileUrl, "_blank");
        },
        deletereport(row){
            var that=this;
            if(row){
                this.$confirm('您确定要删除 "'+row.reportName+'" 么？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    this.$instance.get(this.apiurl.deleteupdatereport,{params:{id:row.id}}).then(res=>{
                            if(res.data.code==0){
                                if(res.data.code==0){
                                    that.$message({
                                        showClose: true,
                                        message: '操作成功！',
                                        type: 'success'
                                    });
                                    that.getList();
                                }
                            }
                    });
                })
            }
        },
        shareofflinereport(row){
            this.sharerow.filename=row.reportName;
            this.sharerow.fileurl=row.fileUrl;
            this.sharerow.type='';
            this.shareVisible=true;
        },
        async init(){
            //初始化日期范围
            this.pageloading=true;
            const nowvalue=new Date();
            const prevalue=new Date();
            const currentMonth = nowvalue.getMonth();
            prevalue.setMonth(currentMonth - 3);
            this.foreDatarange=[this.$dateFormat(prevalue.toLocaleDateString(),"yyyy-MM"),this.$dateFormat(nowvalue.toLocaleDateString(),"yyyy-MM")]
            this.startDate=this.foreDatarange[0];
            this.endDate=this.foreDatarange[1];
           
            //初始化日期结束
            await this.getproductList();//获取权限列表
            if(this.activeName=="yu_ce_bao_gao_edit"){
                this.getforecastreport();
            }
            if(this.activeName=="xian_xia_bao_gao"){
                this.getList();
            }
            this.pageloading=false;
        },
        clickCopy(msg) {
            const save = function(e) {
                e.clipboardData.setData('text/plain', msg)
                e.preventDefault() // 阻止默认行为
            }
            document.addEventListener('copy', save) // 添加一个copy事件
            document.execCommand('copy') // 执行copy方法
            this.$message({ message: '复制成功', type: 'success' })
        },
        getmodule(){
            let module=(this.$store.state.loginRes.moduleList||[]).find((v)=>{return v.moduleCode=='yu_ce_ying_yong'})
            if(module && module.children.filter(o=>o.moduleCode=="bao_gao_ku").length>0){
                for(let item of module.children.filter(o=>o.moduleCode=="bao_gao_ku")[0].children){
                    this.topTablist.push({
                        title:item.moduleName,
                        code:item.moduleCode
                    })
                }
                if(this.topTablist.length>0){
                    this.activeName=this.topTablist[0].code;
                }
            }
        }
    },
    mounted(){
            this.getmodule();
            this.init();
      
    }
}
</script>
<style lang="scss">
.sharedialog{
    .el-dialog__body{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .el-dialog__title{
            font-size: 14px;
    }
    .sharebtn{
        width: 100px;
    }
}
.reporttab{
    .el-tabs__item:focus.is-active.is-focus:not(:active){
        box-shadow: none!important;;
    }
}
</style>